.PostingOverviewTable .ui.table.basic {
    background: #fff;
}

.PostingOverviewTable tbody tr.selected {
    background: #f7f7f7;
}

.PostingOverviewTable tbody tr > td.inDanger {
    background: #ffe5e5 !important;
    color: #ae3f3d;
}

.PostingOverview--table .WithSidebar__leftColumn,
.PostingOverview--table .WithSidebar__rightColumn {
    max-height: 750px;
    overflow-y: auto;
    background: #f5f8f9;
}

td.PostingOverviewTable--account .ui.placeholder {
    margin-bottom: 0.45rem;
    margin-top: 0.45rem;
}