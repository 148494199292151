.ReportingMonthTable {
    padding: 0 0 2rem;
}

.ReportingMonthTable--status {
    display: flex;
    align-items: center;
}


span.ReportingMonthTable--sentStatus {
    padding-left: 15px;
}