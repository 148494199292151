.ui.sidebar.static {
    position: relative;
    height: 100vh !important;
}

.item.SideBarHeaderContainer {
    padding: 7px 1rem !important;
}

.SideBarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SideBarHeader img {
    height: 32px;
}

.inverted .active .link.item .icon{
    color: #61c8ef;
}

/* span.item {
    display: flex !important;
    align-items: center;
} */

 .menu .menu .active .link.item {
    font-weight: 500;
    background: rgba(255,255,255,.08) !important;
    color: #fff !important;
}

.SidebarNav--thirdPartyLinks {
    padding: 0.5rem 1rem 1rem 0;
}

a.SidebarNav--slackLogo {
    display: flex;
    align-items: center;
}

a.SidebarNav--slackLogo img {
    max-height: 50px;
}

a.SidebarNav--slackLogo span {
    color: #fff;
}

.item.SidebarNav--ghostUserSectionContainer {
    padding: .92857143em 1em !important;
}

.SidebarNav--ghostUserSection {
    display: block;
    border: 2px solid rgba(120, 228, 255, 0.85);
    border-radius: 2px;
    padding: 0.7rem 1rem;
    text-align: left;
}


span.SidebarNav--ghostUserHostName {
    font-size: 0.9rem;
    letter-spacing: 0.02em;
    display: block;
    padding: 0 0 8px;
    margin: 0 0 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.59);
}

p.SidebarNav--ghostUserName {
    font-size: 0.85rem;
    letter-spacing: 0.02em;
}

p.SidebarNav--ghostUserName span {
    display: block;
    font-weight: 600;
    letter-spacing: 0.02em;
    font-size: 0.95rem;
}

.ui.sidebar.menu .link.item.SidebarNav--assignments.withLeadView {
    padding-bottom: 0;
}

/* .ui.sidebar.menu a.active .link.item.SidebarNav--assignments {
    padding-bottom: 0;
} */

.link.item.SidebarNav--assignments .menu.SideBarNav--secondaryMenu {
    margin-top: 0;
}

@media (max-width: 1200px) {
    .SidebarNav.ui.left.sidebar {
        width: 75px;
    }
    .SideBarHeader--heading {
        display: none;
    }
    .SidebarNav .SidebarNav--content {
        display: none;
    }
    .SidebarNav .link.item .icon {
        float: none !important;
    }
    .SideBarNav--secondaryMenu {
        display: none;
    }
    .SidebarNav--subMenu {
        display: none;
    }
    .SidebarNav--thirdPartyLinks {
        padding: 0.5rem 0.4rem 1rem 0;
    }
    a.SidebarNav--slackLogo {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    a.SidebarNav--slackLogo span {
        display: none;
    }
}