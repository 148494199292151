.UserAvatar--initials {
    color: #fff;
    font-size: 0.85rem;
    border-radius: 50%;
    height: 27px;
    width: 27px;
    text-align: center;
    line-height: 27px;
    letter-spacing: 0px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.11);
}

.UserAvatar .ui.avatar.image {
    object-fit: contain;
}