.ClientReportingDashboard {
  min-height: 50vh;
  padding: 0 0 2rem;
}

.ClientReportingDashboard--listItem {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  color: #575757;
  height: 100%;
  font-weight: 600 !important;
  padding: 3px 0;
}

.ClientReportingDashboard--listItem__middle {
  min-width: 150px;
}

.ClientReportingDashboard--reportYear {
  margin: 2rem 0 3rem;
}

.ClientReportingDashboard--reportYearHeader {
  display: flex;
  align-items: center;
}

.ClientReportingDashboard--reportYearHeader .content {
  padding-left: 6px !important;
  line-height: 1;
}

.ClientReportingDashboard--reportYearHeader i {
  font-size: 1.1em !important;
}


.ClientReportingDashboard--filters .dropdown {
  margin-right: 1.5rem;
}