.NavBar {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 5px 1rem 0;
    background: #fff;
    border-radius: 0 !important;
    box-shadow: 0 1px 3px 0 rgba(21,27,38,0.15);
    z-index: 10;
}

.NavBarContainer {
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-start;
    align-items: center;
    width: 90%;
}

.NavBarContainer .ui.header {
    margin: 0 0 5px !important;
} 

.NavBarMenu {
    margin: 0 0 0 2rem !important;
    border-radius: none !important;
    box-shadow: none !important;
    border: none !important;
}

.item.noLink {
    padding: 0px 0.5em 6px 1.14285714em !important;
}

.NavBarMenu .link.item {
    font-size: 0.9rem !important;
    /* padding: 1em 1.14285714em !important; */
    padding: 1em 1em !important;
}

.NavBarMenu .active .item {
    border-color: #1b1c1d !important;
}

.NavBarMenu .active .item.noLink,
.NavBarMenu .selection.dropdown .item {
    border-color: transparent !important;
}

.NavBarMenu .noLink .active.item   {
    border-color: transparent !important;
}


.NavBarContainer .item .dropdown.button {
    font-size: 0.85em;
    font-weight: 500;
}


.NavbarMessage {
    margin: 0 0 0 0.5rem !important;
    padding: 0.65em 1.5em 0.7em !important;
}