.ContentDashboard--articleHistoryCharts {
    display: block;
    margin: 0 0 2rem;
}

.ContentDashboard--articleHistoryChartsLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.CarouselSlide .ui.grid {
    margin-left: 0;
    margin-right: 0;
}

.ContentDashboard--actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 2rem;
}

.ContentDashboard--actions div:first-child button.button {
    margin-right: 1rem;
}