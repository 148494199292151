.ArticleHistoryChartFigure.incomplete {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
}

.ArticleHistoryChartFigure.incomplete {
    color: #14aaf5;
}

.ArticleHistoryChartFigure.complete {
    color: #00bf9c;
}

.ArticleHistoryChartFigure.late {
    color: #f5143c;
}

.ArticleHistoryChartFigure--top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 800;
    margin: 0 0 5px;
}

.ArticleHistoryChartFigure--indicator {
    display: block;
    width: 10px;
    height: 10px;
    background: #04bfec;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.ArticleHistoryChartFigure.incomplete .ArticleHistoryChartFigure--indicator {
    background: radial-gradient(ellipse at center, #16ABF5, #16ABF5CC);
}

.ArticleHistoryChartFigure.complete .ArticleHistoryChartFigure--indicator {
    background: radial-gradient(ellipse at center, #00BF9D, #00BF9ECC);
}

.ArticleHistoryChartFigure.late .ArticleHistoryChartFigure--indicator {
    background: linear-gradient(to bottom right, #F5153D, #F5163ECC);
}
span.ArticleHistoryChartFigure--offset {
    color: #333;
    font-size: 0.8rem;
    margin-left: 5px;
    letter-spacing: 0.02em;
}