.inline.field {
	display: flex;
	align-items: center;
}

.inline.field label {
	width: 95px !important;
}

.inline.field .ui.input,
.inline.field input,
.inline.field .ui.selection,
.inline.field textarea,
.inline.field label + div {
	flex: 1;
}

.inline.field label + div .ui.input {
	width: 100% !important;
}

main.MainContent {
	display: flex;
	height: 100vh;
}

.RouteContainer {
	width: 100%;
	max-height: 100vh;
	height: 100%;
	overflow-y: auto;
}

.alignCenter {
	text-align: center !important;
}

.alignLeft {
	text-align: left !important;
}

.alignRight {
	text-align: right !important;
}

.ttoBlue {
	color: #67daf9 !important;
}

.centerButton {
	margin: 0 auto !important;
}

.selectable {
	cursor: pointer;
}

/* .selectable:hover {
  background: #eee;
} */

.selectable.selected {
	background: #eee;
	font-weight: 600;
}

.textCenter {
	text-align: center;
}

.inlineButtonHolder {
	margin: 0 0 0.7rem;
}

.inlineSelectButton {
	margin: 0 !important;
}

.ui.multiple.selection.dropdown {
	margin-right: 5px !important;
}

span.formOr {
	display: flex;
	align-items: center;
	color: #9a9a9a;
	margin: 0 2px;
}

.inlineButton {
	align-self: flex-end;
	margin-bottom: 2px !important;
}

.field.fullWidthDate {
	width: 100% !important;
}

.field.fullWidthDate i {
	display: none;
}

.field.fullWidthDate .ui.input input {
	width: 100% !important;
	flex: 1 100%;
}

.caps {
	text-transform: capitalize;
}

.ui.card > .content > .description p {
	word-break: break-word;
}

.WithSidebar .ui.tabular.menu .item i.icon {
	margin-right: 0.7rem;
}

.WithSidebar .ui.secondary.menu .item:first-child {
	padding-left: 0 !important;
}

.noBorder .ui.segment {
	border: none !important;
	padding: 0.5rem;
}

.ui.checkbox.alignMiddle {
	vertical-align: middle;
}

.ui.compact.table tbody.extraPadding td {
	padding: .6em 1em;
}

.ui.structured.table thead th.muted {
	background: #fff;
}

.field.centerCheckbox {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.field.centerCheckbox > label {
	flex: 1;
}

.field.centerCheckbox > .checkbox {
	flex: 1;
}

.floatUp {
	transform: translateY(-2px);
}

.header.mutedHeader {
	color: #6b6666 !important;
}

td button.ui.button {
	padding: .7571429em 1.5em .78571429em;
}

.overflowVisible {
	overflow: visible !important;
}

.ui.form .field + .ui.divider {
	margin-top: 1.7rem;
}

li.recharts-legend-item {
	display: inline-flex !important;
	align-items: center;
	margin-left: 10px;
}

li.recharts-legend-item span {
	font-size: 0.95rem;
}

.tableBorderLeft {
	border-left: 1px solid rgba(34, 36, 38, .1) !important;
}

.DropdownItemContainer > span {
	font-weight: 600;
	margin-left: 3px;
	font-size: 0.9rem;
	line-height: 1;
}

.brandIcon img {
	width: 15px;
	display: block;
	margin: 0 auto;
}

.ui.labeled.icon.button > .icon:after,
.ui.labeled.icon.button > .icon:before,
.ui.labeled.icon.buttons > .button > .icon:after,
.ui.labeled.icon.buttons > .button > .icon:before {
	line-height: 2;
}

/*** Animations ***/

.pulse {
	transform: scale(1);
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	animation: pulse-black 2s infinite;
}

.pulse.pulse-white {
	animation: pulse-white 2s infinite;
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
}

.pulse.pulse-purple {
	box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
	animation: pulse-purple 2s infinite;
}

.pulse.pulse-red {
	box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
	animation: pulse-red 2s infinite;
}

.pulse.pulse-orange {
	box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
	animation: pulse-orange 2s infinite;
}

.pulse.pulse-yellow {
	box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
	animation: pulse-yellow 2s infinite;
}

.pulse.pulse-blue {
	box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
	animation: pulse-blue 2s infinite;
}

.pulse.pulse-green {
	box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
	animation: pulse-green 2s infinite;
}

@keyframes pulse-black {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse-white {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}


@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}


@keyframes pulse-orange {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
	}
}


@keyframes pulse-yellow {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
	}
}


@keyframes pulse-blue {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
	}
}


@keyframes pulse-green {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
	}
}


@keyframes pulse-purple {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
	}
}

@keyframes loading-placeholder {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

