.PresentationComponent {
    display: block;
    text-align: center;
    margin: 4rem auto;
    padding: 0 3rem;
    max-width: 800px;
}

.PresentationComponent--container.withConvertedImage {
    display: none;
}

.PDFView--page .PresentationComponent {
    margin: 0rem auto 2rem;
    padding: 0 1.5rem;
}

.PresentationComponent--container.toConvert {
    padding: 0 1px;
}