
.InvitationsTable {
    overflow-y: auto;
    max-height: 80vh;
}

.reinviteAction {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}