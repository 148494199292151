.AssignmentListItem {
    display: flex;
    justify-content: space-between;
    padding: 3px 1.5rem;
}

.AssignmentListItemMeta {
    display: flex;
    text-align: right;
}

.AssignmentListItemMeta--item {
    margin-left: 1.2rem;
}

span.dd {
    font-size: 0.9rem;
    min-width: 105px;
    display: inline-block;
}

.dd-due {
    margin-right: 5px;
    font-weight: 600;
    color: #0c0c0c;
    font-size: 0.88rem;
}

.AssignmentListItemMeta--item.assignmentStatus {
    min-width: 150px;
    /* text-align: right; */
}


.AssignmentListItemMeta--item.assignmentDueDate {
    min-width: 100px;
    /* text-align: right; */
}


.item.completed {
    filter: opacity(0.5) grayscale(20%);
}

.customDatepicker {
    display: inline-block;
}

.customDatepicker .input {
    width: 100px;
}

.customDatepicker .input input {
    cursor: pointer !important;
}

span.AssignmentListItemMeta--item__content {
    min-width: 120px;
}

.AssignmentListItemMeta--item.assignmentPlanningYearMonth {
    min-width: 115px;
}