.FormHeader {
    position: sticky;
    background: #fff;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem;
    padding: 1rem 0;
    z-index: 8;
}

.FormHeader .ui.header {
    margin: 0 !important;
}

.FormHeaderActions .button {
    margin-left: 5px !important;
}