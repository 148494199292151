.PopupNotification--body {
    /* padding: 1.3em 2em !important; */
    position: relative;
}

.PopupNotification--body.positive {
    border: none !important;
    border-top: 2px solid rgba(34, 186, 70, 0.6) !important;
    /* box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent !important; */
    box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent, 0 1px 2px 0 rgb(34 36 38 / 15%) !important;
}

.PopupNotification--body.negative {
    /* border-top: 2px solid hsla(360, 71%, 51%, 0.6) !important; */
    border: none !important;
    border-top: 2px solid hsla(360, 71%, 51%, 0.6) !important;
    box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent, 0 1px 2px 0 rgb(34 36 38 / 15%) !important;
    
}

.ui.header.PopupNotification--header {
    margin-top: 0 !important;
    line-height: 1;
    margin-bottom: 0.5rem;
}

h3.ui.header.PopupNotification--header .content {
    color: #333;
}

h4.ui.header.PopupNotification--header {
    font-size: 1.15rem;
}

.PopupNotification--content {
    color: #444;
    font-weight: 500;
}

.PopupNotification--closeTrigger {
    text-align: center;
    padding: 0.35rem 0 0;
}

.PopupNotification--closeButton {
    display: block;
    position: absolute;
    top: 2px;
    right: 7px;
    line-height: 1;
    color: #000;
    font-size: 1.2rem;
    cursor: pointer;
}

.PopupNotification--content p:not(:last-child) {
    margin-bottom: 0.5rem;
}