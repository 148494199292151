.ContentArticleForm {
    position: relative;
}

.ContentArticleForm--Form {
    margin: 0 5px;
}

.buttonHolder {
    display: flex;
    justify-content: space-between;
}

.AdvancedButton {
    margin: 0 0 0.8rem !important;
}

.AdvancedButton.Open {
    margin: 0.8rem 0 0.8rem !important;
}

.KeywordDropDown .menu .item:not(last-child) {
    border-bottom: 1px solid #ddd !important;
}

.contentComments {
    margin-bottom: 1rem;
}

.ContentArticleCommentSection {
    margin: 1rem 0 0;
}

.ui.label.clientOption--label {
    padding: .3em .833em;
    letter-spacing: 0.01em;
    margin-left: 3px !important;
}

.ContentArticleForm .DividerHeader {
    margin: 1.7rem 0 1.8rem;
}

@media only screen and (min-width: 992px) {
    .ui.selection.dropdown .menu {
    max-height: 25rem !important;
}
}

.inputAction .button {
    margin-left: 0.4rem !important;
}

.ui.button.googleGreen {
    background: #33A852;
    color: #fff;
}

.inputAction .ui.button {
    margin: 0 !important;
}

.ui.form .fields.selectedMonthsField>label {
    margin: 0 0.85714286em 0 0;
    width: 95px !important;
    vertical-align: baseline;
    font-size: .92857143em;
    font-weight: 700;
    color: rgba(0, 0, 0, .87);
    text-transform: none;
}

.ui.form .fields.selectedMonthsField {
    margin: 0 0 1em;
}

.selectedMonthsField--container {
    display: flex;
    flex: 1;
    width: 100%;
    flex-wrap: wrap;
}

.selectedMonthsField--container .field {
display: flex;
    flex: 1 1 33%;
    margin-bottom: 2px !important;
}


.selectedMonthsField--container .field .ui.checkbox {
    margin-right: 6px;
}

.selectedMonthsField--container .field label {
    line-height: 17px;
}

.selectedMonthsField--data {
    flex: 1;
}

.selectedMonthsField--actions {
display: flex;
    align-items: center;
    margin-bottom: 7px;
}

span.selectedMonthsField--action {
font-weight: 600;
    color: #3f80dd;
    margin-right: 2rem;
    cursor: pointer;
}