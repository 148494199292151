.PresentationCreatorPreviewSlide {
    display: block;
    padding: 1rem 1rem;
    min-height: 500px;
}

.PresentationCreatorPreviewSlide .segment:first-of-type {
    min-height: 600px;
}

a.active.item.PresentationCreatorPreview--slideTab span {
    font-weight: 600;
}


.PresentationCreatorPreviewSlide--header {
    text-align: center;
    margin-top: 1rem !important;
}