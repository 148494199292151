.AccountHealthOverviewListItem {
    display: block;
    background: #fff;
    padding: 1rem 1rem;
    margin: 1rem 0 1.5rem;
    border-radius: 2px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5), 0px 2px 6px -5px rgba(0, 0, 0, 0.18);
    /* box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75), 0px 3px 12px -8px rgba(0, 0, 0, 0.28); */
    transition: 0.25s ease-in-out all;
}

/* .AccountHealthOverviewListItem:hover {
    transform: translateY(-1px);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.9), 0px 3px 12px -6px rgba(0, 0, 0, 0.28);
} */

section.AccountHealthOverviewListItem--header {
    display: flex;
    /* align-items: center; */
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px solid #e2e2e2;
}

.ui.header.AccountHealthOverviewListItem--heading .content {
    font-size: 1.2rem;
}

.AccountHealthOverviewListItem--cellHeader .ui.header {
    font-weight: 500;
    font-size: 0.95rem;
    color: #444;
}

.AccountHealthOverviewListItem--monthsIntoContractOutput {
    font-weight: 600;
    font-size: 0.9rem;
    color: #444;
}

.AccountHealthOverviewListItem--monthsIntoContractOutput.active {
    color: #296c9c;
}

.AccountHealthOverviewListItem--monthsIntoContractOutput i.icon {
    margin-right: 6px;
}

.AccountHealthOverviewListItem--cellHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
}

.AccountHealthOverviewListItem--cellHeader .ui.header {
    margin: 0;
}

.AccountHealthOverviewListItem--cellHeader i.icon {
    opacity: 0.9;
    /* opacity: 0; */
    color: #296c9c;
}

.AccountHealthOverviewListItem--cellContent {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0 0rem;
}

.AccountHealthOverviewListItem--cellContent > * {
    flex: 5;
}

.AccountHealthOverviewListItem--cellContent > *:not(:first-child) {
    flex: 7;
}

.AccountHealthOverviewListItem--pageSpeed .AccountHealthOverviewListItem--cellContent > * {
    flex: 3;
}

.AccountHealthOverviewListItem--pageSpeed .AccountHealthOverviewListItem--cellContent > *:not(:first-child) {
    flex: 6;
}

.AccountHealthOverviewListItem--cellContentSingle {
    font-size: 1.6rem;
    font-weight: 600;
    color: #333;
}

.condensed .AccountHealthOverviewListItem--list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.AccountHealthOverviewListItem--listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2px;
    font-size: 0.85rem;
    color: #444;
}

.condensed .AccountHealthOverviewListItem--listItem {
    flex-grow: 1;
    flex-basis: 45%;
}

.condensed .AccountHealthOverviewListItem--listItem:nth-child(odd) {
    margin-right: 20px;
}

.AccountHealthOverviewListItem--listItemLabel {
    font-weight: 600;
    color: #525252;
}

.AccountHealthOverviewListItem--listItemOutput .positive {
    color: #1cb963;
}

.AccountHealthOverviewListItem--listItemOutput .negative {
    color: #ec3333;
}

.AccountHealthOverviewListItem--noData {
    font-weight: 600;
    color: #333;
}

.AccountHealthOverviewListItem--listItemOutput.noData {
    font-size: 1rem;
    min-width: 30px;
    text-align: center;
}

a.AccountHealthOverviewListItem--domain {
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 0.95rem;
    color: #737373;
}

a.AccountHealthOverviewListItem--domain:hover {
    color: #2085d0;
}

a.AccountHealthOverviewListItem--domain i.icon {
    font-size: 0.8rem;
    margin-left: 5px;
}

.AccountHealthOverviewListItem--cellContentSingle i.icon {
    margin-left: 10px;
    opacity: 0.8;
}

i.icon.contractMonthsIcon {
    color: #0e6880;
}

.AccountHealthOverviewListItem--cell.active .ui.header {
    font-weight: 600;
    color: #296c9c;
}

.AccountHealthOverviewListItem--dataCalculation {
    display: inline-flex;
    align-items: flex-start;
}

.AccountHealthOverviewListItem--dataCalculation.small {
    font-size: 0.8rem;
    display: block;
}

.AccountHealthOverviewListItem--dataCalculation:not(:last-child) {
    margin-bottom: 3px;
}

span.AccountHealthOverviewListItem--difference {
    font-size: 1rem;
    line-height: 1;
    margin-left: 8px;
}

span.AccountHealthOverviewListItem--difference .positive {
    color: #1cb963;
}

span.AccountHealthOverviewListItem--difference .negative {
    color: #ec3333;
}

span.AccountHealthOverviewListItem--listItemOutput span.AccountHealthOverviewListItem--listItemOutputItem:nth-child(2) {
    margin-left: 8px;
}

.AccountHealthOverviewListItem--leadsOverTime span.AccountHealthOverviewListItem--listItemOutputItem {
    min-width: 30px;
    display: inline-block;
}

/* span.AccountHealthOverviewListItem--listItemOutput {
    min-width: 95px;
} */

span.smallText {
    font-size: 0.87rem;
}

.AccountHealthOverviewListItem--scoreList {
    display: flex;
    flex-wrap: wrap;
}

.AccountHealthOverviewListItem--scoreListItem {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 0.85rem;
    flex-grow: 1;
    flex-basis: calc(50% - 20px);
}

.AccountHealthOverviewListItem--scoreListItem:first-child {
    margin-bottom: 10px;
}

.AccountHealthOverviewListItem--scoreListItem:nth-child(odd) {
    margin-right: 20px;
}

span.AccountHealthOverviewListItem--scoreListItemLabel {
    font-weight: 600;
    color: #525252;
}

.AccountHealthOverviewListItem--score .ProgressCircle .radialProgressBar .overlay {
    font-size: 1.6rem !important;
    font-weight: 500;
}

.AccountHealthOverviewListItem--scoreListItem .ProgressCircle .radialProgressBar .overlay {
    font-weight: 600;
}

span.AccountHealthOverviewListItem--scoreListItemScore {
    margin-bottom: 2px;
}