.EditorialRequirementsDetail {
    margin: 1rem 0;
}

.EditorialRequirementsDetail--item {
    font-size: 0.9rem;
    font-weight: 600;
    color: #444444;
    border: 1px solid #cacaca;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.09);
    padding: 7px 8px 5px;
    border-radius: 3px;
}