.Assignments {
    position: relative;
    min-height: 70vh;
}

.Assignments--dropdownHolder .dropdown {
    margin-right: 1rem;
}

.Assignments--dropdownHolder {
    padding: 0 0 1rem;
}

.Assignments--actions {
    display: flex;
    margin-bottom: 1rem;
}