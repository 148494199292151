.noOrgReqs, .editorialReqs {
    margin: 1rem 0;
}

.OrganizationForm .inline.field label {
    width: 120px !important;
}

.ui.horizontal.divider.OrganizationForm--divider span {
    font-size: 0.95rem;
    color: #444;
    text-transform: none;
}

.OrganizationForm .fields .inline.field + .inline.field label {
    width: auto !important;
    margin-left: 15px;
}

.OrganizationForm--imageFieldContainer {
    display: flex;
    align-items: center;
}

.OrganizationForm--imageFieldContainer img {
    margin-right: 2rem;
}