.ReportEmailEntries {
    padding: 1rem 0;
    min-height: 300px;
}

.ReportEmailEntries-entryInfo {
    font-weight: 600;
    color: #444;
}

.ReportEmailEntries--noData {
    text-align: center;
    font-weight: 600;
    color: #444;
    margin: 1rem 0;
}

.ReportEmailEntry {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 1rem;
    margin: 5px 0 0;
}

.ReportEmailEntry-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 20px;
}

.ReportEmailEntry-content {
    display: block;
    margin: 0 2rem 0 1rem;
    color: #333;
}

.ReportEmailEntries--divider {
    margin: 0 0 1rem !important;
}

.ui.header.ReportEmailEntries--header {
    color: #484848 !important;
}

.ReportEmailEntry-date {
    color: #828282;
    font-size: 0.95rem;
    font-weight: 300;
}

.ReportEmailEntry-date span {
    vertical-align: middle;
}

span.ReportEmailEntry-content--email {
    font-weight: 600;
    color: #484848;
    font-size: 0.97rem;
    cursor: pointer;
    transition: 0.17s ease-in-out all;
}

span.ReportEmailEntry-content--email:hover {
    color: #2085d0;
}

.ReportEmailEntry-label .ui.label {
    background-color: #a5a5a5;
    opacity: 0.75;
}

.ReportEmailEntries--filterAreaContainer {
    min-height: 25px;
    margin: 0.5rem 0 0.7rem;
    padding: 0 0.35rem;
}

.ReportEmailEntries--filterArea {
    display: flex;
    align-items: center;
}

span.ReportEmailEntries--filterAreaLabel i {
    margin-right: 8px;
    color: #565656;
}

.ReportEmailEntries--filterArea.withFilter .ReportEmailEntries--filterAreaLabel i {
    color: #2085d0;
}

span.ReportEmailEntries--filterAreaOutput {
    margin: 0 1rem 0 7px;
    font-weight: 600;
}

span.ReportEmailEntries--filterAreaCancel .ui.button {
    font-size: 0.6rem;
}

.ReportEmailEntries--filterAreaDisplay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.2rem;
}

.ReportEmailEntries--bottomActions {
    display: block;
    margin: 1.5rem 0 1rem;
    padding: 0 0.5rem;
}

.ReportEmailEntries--bottomActions .ui.button .ui.icon.button i {
    margin-right: 6px !important;
}