.UtilitiesDashboard--links {
    margin: 2rem 0;
}

.UtilitiesDashboard--link {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.22), 0px 7px 20px -9px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    border-radius: 4px;
    transition: 0.17s ease-in-out all;
}

.UtilitiesDashboard--link:hover {
    transform: translateY(-2px);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.22), 0px 9px 24px -7px rgba(0, 0, 0, 0.2);
}