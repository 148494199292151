.AppListItem {
  display: flex;
  align-items: center;
  margin: 1.2rem 0;
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 3px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08),
    0px 5px 11px -4px rgba(0, 0, 0, 0.12);
}

.AppListItem--content {
  margin-left: 2rem;
  display: flex;
  width: 100%;
}

.AppListItem--content__left {
  flex: 1;
}

.AppListItem--content__right {
  flex: 1;
  text-align: right;
}

.AppListItem--header {
  margin-top: 0 !important;
}

.AppListItem--header__content {
    color: #075b71;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  letter-spacing: 1px;
}

.AppListItem--header__subheader {
  font-weight: 600 !important;
  text-transform: none !important;
}

.AppListItem--header__subheader--link {
  color: inherit;
}

.AppListItem--header__subheader i {
  margin-left: 5px !important;
}

.AppListItem--header__subheader--link:hover {
  color: inherit;
  filter: brightness(115%);
}

.AppListItem--details {
  display: flex;
  flex-direction: column;
}

.AppListItem--details--header {
  font-weight: 600;
  color: #505050;
}

.AppListItem--settingsButton {
    display: inline-block;
    margin-left: 0.5rem;
    cursor: pointer;
}