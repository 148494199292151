.PresentationCreatorToolbar--component {
    margin: 0.8rem 0;
    font-weight: 600;
    color: #444;
}

.PresentationCreatorToolbar--component {
    margin: 1rem 0;
    font-weight: 600;
    color: #444;
    cursor: pointer;
    transition: 0.12s ease-in-out all;
}

.PresentationCreatorToolbar--component .ui.segment {
    transition: 0.12s ease-in-out all;
    padding: 0.8em 1em;
}

.PresentationCreatorToolbar--component:hover .ui.segment {
    background: #f7f7f7;
    box-shadow: 0 1px 2px 0px rgba(34,36,38,.1);
    border: 1px solid rgba(34,36,38,.1);
}