.UserSelectWidget {
    display: flex;
    height: 27px;
    width: 27px;
    line-height: 27px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px dashed #c5c5c5;
    transition: .12s ease all;
    cursor: pointer;
}


.UserSelectWidget:hover {
    border: 1px solid #484848;
}

.UserSelectWidget.withAvatar {
    border: 1px solid #c5c5c5;
}

.UserSelectWidget i.icon {
    margin: 0;
    width: auto;
    height: auto;
    color: #c5c5c5;
    transition: .12s ease all;
}

.UserSelectWidget:hover i.icon {
    color: #484848;
}