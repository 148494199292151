span.Presentation--slideMenuItem__count {
    margin-right: 5px;
}

.Presentation--slideMenuItem {
    /* padding: 0.85em 1.5em !important; */
    font-size: 0.95rem;
}

.Presentation--header {
    margin: 0 0 1.5rem;
    color: #444;
    text-align: center;
}

.Presentation--header.withActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Presentation--header.withActions .ui.header {
    margin: 0;
}

.Presentation--headerActions {
    display: flex;
    align-items: center;
}

.Presentation--headerActions > * {
    margin-left: 0.5rem;
}