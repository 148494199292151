
.WhatConvertsChart--chart {
    display: flex;
    align-items: center;
    margin: 1rem auto 3rem;
}

.WhatConvertsChart--chart .recharts-wrapper {
    margin: 0 auto;
}

.WhatConvertsChart--table__total {
    font-weight: 900;
    background: #f3f4f5;
}

.WhatConvertsChart--table__source {
    display: flex;
    align-items: center;
}

.WhatConvertsChart--colorLabel {
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 2px;
    opacity: 0.85;
    margin-right: 7px;
}

.WhatConvertsChart--chartIcon i {
    color: #05a8d1;
}

.WhatConvertsChart-noData .ui.header {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.WhatConvertsChart--chart line.recharts-xAxis.xAxis {
    display: none;
}

.WhatConvertsChart--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5rem;
}

.WhatConvertsChart--header .ui.header {
    margin: 0;
}

.WhatConvertsChart--chartLabel {
    margin: 1rem 0 3rem;
}

.WhatConvertsChart--chartLabelText {
    display: block;
    transform: rotate(-90deg) translate(1rem, 2.5rem);
    color: #222;
    letter-spacing: 0.025em;
    font-size: 0.95rem;
}

.WhatConvertsChart--table {
    display: block;
    width: 92.5%;
    margin: 0 0 0 auto;
    font-size: 1.15rem;
}

.PDFView--content .WhatConvertsChart--chart {
    font-size: 0.85rem;
}

.PDFView--content .WhatConvertsChart .recharts-legend-wrapper {
    bottom: -5px !important;
}