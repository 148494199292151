.UserDataItem {
    margin: 0 0 0.8rem;
    border-bottom: 1px solid #efefef;
    padding: 0.2rem 0 0.8rem;
}

.UserDataItem__label {
    display: block;
    font-size: 0.9rem;
    color: #797979;
    padding: 0 0 2px;
}

.UserDataItem__content {
    display: block;
    font-weight: 600;
    color: #444;
}

.UserData .FormHeader {
    padding: 0 0 1rem;
    margin: 0 0 1.3rem;
}

.UserDataItem--field {
    margin: 4px 0 0;
}

.UserDataItem__content input, .UserDataItem__content .dropdown {
    font-weight: 600 !important;
    color: #575757 !important;
    font-size: 0.9rem !important;
}

.UserData .ui.secondary.pointing.menu .active.item,
.UserData .ui.secondary.pointing.menu .active.item:hover {
    border-color: #07b9e5;
}

.UserData .FormHeaderActions {
    display: flex;
}

/* .UserData .ui.secondary.pointing.menu .active.item i {
    color: #06a4cb;
} */