.Organizations {
    width: 100%;
    padding: 1.5rem 2rem;
}

.sidebarActions {
    margin: 0 0 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebarActions .ui.header {
    margin: 0;
}