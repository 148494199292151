.UserDataAccounts {
    display: block;
    min-height: 60vh;
}

.UserDataAccounts .ui.table thead th {
    padding: 0.7em 1em;
}

.UserDataAccounts--actions {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
}


.UserDataAccounts--form {
    margin: 1rem 0;
    padding: 1.2rem 0;
    border-top: 1px solid rgba(34,36,38,.1);
}

.UserDataAccounts .isUpdating {
    position: relative;
    overflow: hidden;
}

.UserDataAccounts .isUpdating:before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 600%;
    background: rgba(238, 238, 238, 0.5);
    background: #EEEEEE4D;
    background: -webkit-linear-gradient(left, #EEEEEE4D, #EEEEEE99);
    background: -moz-linear-gradient(left, #EEEEEE4D, #EEEEEE99);
    background: linear-gradient(to right, #EEEEEE4D, #EEEEEE99);
    animation: 1.1s loading-placeholder cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
}

.lowPadding {
    min-height: 1.51428571em !important;
    padding: .58571429em 2.1em .58571429em 1em !important;
}