.ui.segment.FeedbackForm--instructions-container {
    border-color: rgba(34,36,38,.15);
    box-shadow: 0 2px 25px 0 rgba(34,36,38,.05) inset;
    background: #f9fafb;
}

.ui.segment.FeedbackForm--instructions-container {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    padding: 0.8em 1em 1em;
}

ol.ui.list.FeedbackForm--instructions-list li:before {
    content: counter(ordered) ". ";
}

p.FeedbackForm--instructions__label {
    margin-bottom: 0rem !important;
}

ol.ui.list.FeedbackForm--instructions-list {
    margin-top: 0.5rem !important;
}