.ArticleDetailItem--heading {
    color: #575757;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9rem;
}

.ArticleDetailItem--content {
    color: #333333;
    font-weight: 600;
}

.ArticleDetailItem {
    margin: 0 0 1rem;
    position: relative;
}

.InlineLoader {
    margin-top: 5px;
    line-height: 1;
}

.InlineLoader .InlineLoader--loadingText {
    margin-left: 7px;
    font-size: 0.85rem;
    color: #555;
}