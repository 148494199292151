.ArticleChangelog {
    padding: 1rem 0;
}

.ArticleChangelog-entryInfo {
    font-weight: 600;
    color: #444;
}

.ArticleChangelog--noData {
    text-align: center;
    font-weight: 600;
    color: #444;
    margin: 1rem 0;
}

.ArticleChangelogEntry {
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 0.5rem;
    margin: 5px 0 0;
}

.ArticleChangelogEntry-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 20px;
    margin-right: 5px;
}

.ArticleChangelogEntry-content {
    display: block;
    margin: 0 2rem 0 0rem;
    color: #333;
    font-size: 13px;
}

.ArticleChangelog--divider {
    margin: 0 0 1rem !important;
}

.ui.header.ArticleChangelog--header {
    color: #484848 !important;
}

.ArticleChangelogEntry-date {
    color: #828282;
    font-weight: 300;
    margin-left: 20px;
    line-height: 1;
        font-size: 12px;
}

.ArticleChangelogEntry-date span {
    vertical-align: middle;
}

span.ArticleChangelogEntry-content--email {
    font-weight: 600;
    color: #484848;
    font-size: 0.97rem;
    cursor: pointer;
    transition: 0.17s ease-in-out all;
}

span.ArticleChangelogEntry-content--email:hover {
    color: #2085d0;
}

.ArticleChangelogEntry-label .ui.label {
    background-color: #a5a5a5;
    opacity: 0.75;
}

.ArticleChangelog--filterAreaContainer {
    min-height: 25px;
    margin: 0.5rem 0 0.7rem;
    padding: 0 0.35rem;
}

.ArticleChangelog--filterArea {
    display: flex;
    align-items: center;
}

span.ArticleChangelog--filterAreaLabel i {
    margin-right: 8px;
    color: #565656;
}

.ArticleChangelog--filterArea.withFilter .ArticleChangelog--filterAreaLabel i {
    color: #2085d0;
}

span.ArticleChangelog--filterAreaOutput {
    margin: 0 1rem 0 7px;
    font-weight: 600;
}

span.ArticleChangelog--filterAreaCancel .ui.button {
    font-size: 0.6rem;
}

.ArticleChangelog--filterAreaDisplay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.2rem;
}

.ArticleChangelog--bottomActions {
    display: block;
    margin: 1.5rem 0 1rem;
    padding: 0 0.5rem;
}

.ArticleChangelog--bottomActions .ui.button .ui.icon.button i {
    margin-right: 6px !important;
}

.ArticleChangelogEntry--info {
    display: flex;
align-items: baseline;
}

span.ArticleChangelogEntry--name {
    font-weight: 600;
}

span.ArticleChangelogEntry--field {
    font-weight: 600;
    color: #1e75b8;
}

span.ArticleChangelogEntry--prevValue {
    font-weight: 600;
    color: #1e75b8;
}

span.ArticleChangelogEntry--newValue {
    font-weight: 600;
    color: #1e75b8;
}