.Login {
    display: flex;
    margin: 0% auto 4rem;
    max-width: 800px;
    min-width: 600px;
    border-radius: 4px;
    min-height: 300px;
    /* box-shadow: 0 0 0 1px rgba(0,0,0,.08),0 1px 4px rgba(0,0,0,.1); */
    box-shadow: 0 0 0px 1px rgba(0,0,0,.1), 0 1px 7px rgba(0,0,0,.12);
    background: #fff;
}

.LoginForm {
    width: 100%;
    padding: 1.5rem 3rem 2.5rem;
    overflow: hidden;
}

.AuthSidebar {
    padding-top: 20px;
    width: 60px;
    background: #564f64;
    background-image: none;
    background-image: linear-gradient(-180deg,rgba(52,44,62,0),rgba(52,44,62,.5));
    box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
    border-radius: 4px 0 0 4px;
    margin-top: -1px;
    margin-bottom: -1px;
    text-align: center;
}

.asAdmin .AuthSidebar {
    background: #326c81;
    background-image: none;
    background-image: linear-gradient(-180deg,rgba(15, 44, 62, 0),rgba(20, 44, 62, 0.5));
}

.LoginForm .ui.icon.input>i.icon {
    transition: 0.12s ease-in-out all;
}

.LoginForm .ui.icon.input>input:focus~i.icon {
    color: #30bfe5 !important;
}

.ui.button.LoginForm--forgotPasswordButton {
    transition: 0.25s ease-in-out all;
}

.ui.button.LoginForm--forgotPasswordButton.highlight {
    color: rgba(163, 51, 200, 0.8) !important;
    box-shadow: 0 0 0 2px rgba(163, 51, 200, 0.5) inset;
}

.LoginForm--inputs {
    margin-bottom: 1rem;
    display: flex;
    position: relative;
    width: 100%;
    min-height: 150px;
}

.LoginForm--userInputs {
    display: block;
    position: absolute;
    width: 100%;
    transform: translateX(0px);
    transition: 0.3s ease-in-out transform;
}

.showingAdminFields .LoginForm--userInputs {
    transform: translateX(-2000px);
}

.LoginForm--adminInputs {
    display: block;
    position: absolute;
    width: 100%;
    transform: translateX(2000px);
    transition: 0.3s ease-in-out transform;
}

.showingAdminFields .LoginForm--adminInputs {
    transform: translateX(0px);
}

.asAdmin .buttonHolder .buttonHolder--left .button {
    min-width: 130px;
}

.buttonHolder--section .ui.button {
    margin-right: 0.5rem;
}

.buttonHolder .icon.ui.button i {
    margin-left: 5px !important;
}