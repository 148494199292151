.DepartmentPickerWidget {
    min-width: 80px;
}

.DepartmentPickerWidget--departmentPill {
    display: inline-block;
    background: #7d7d7d;
    border-radius: 10px;
    font-weight: 600;
    letter-spacing: .5px;
    color: #fff;
    font-size: 0.75rem;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    padding: 3px 12px 4px;
    line-height: 1;
    cursor: pointer;
}

.DepartmentPickerWidget--placeholder {
    font-size: 0.8rem;
    font-weight: 600;
    color: #9a9a9a;
    cursor: pointer;
    line-height: 1;
}
