.AssignmentListTable--Table tr.selected {
    background: #eee;
    font-weight: 600;
}

span.AssignmentListTable--duedate {
    font-weight: 600;
}

span.AssignmentListTable--duedate.late {
    color: #ff3434;
}

span.AssignmentListTable--duedate.late span.noFormat {
    color: #636363 !important;
    font-weight: 500 !important;
}

.AssignmentListTable span.StatusPill {
    min-width: 85px;
}

.AssignmentListTableFilters {
    display: flex;
    align-items: center;
    margin: 0 0 1rem;
}

.AssignmentListTableFilters .TableFilter .ui.selection.dropdown {
    min-width: 16em;
}