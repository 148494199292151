.DatePickerWidget,
.DatePickerWidget input  {
    cursor: pointer;
}

.DatePickerWidget input {
    font-weight: 600;
}

.DatePickerWidget .today input {
    color: #15b531 !important;
}

.DatePickerWidget .late input {
    color: #ff3434 !important;
}