.UserTable {
    overflow-y: auto;
    max-height: 75vh;
    padding: 0 1rem 0 0;
}

.UserTable .ui.sortable.table thead th {
    border-left: none;
}

.UserTable .ui.sortable.table thead th:hover, 
.UserTable .ui.sortable.table thead th.sorted:hover{
    background: transparent;
    color: rgba(0,0,0,.58);
}

.UserTable .ui.sortable.table thead th.sorted {
    background: transparent;
}