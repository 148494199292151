.PresentationCreatorSlideTemplate--main {
    min-height: 500px;
    margin-top: 2rem;
}


.PresentationCreatorSlideTemplate--steps i {
    color: #6290cc;
}


section.PresentationCreatorSlideTemplate--title label {
    display: block;
    margin-bottom: 3px;
    font-size: 0.95rem;
    font-weight: 500;
}

section.PresentationCreatorSlideTemplate--title input {
    font-weight: 600;
}