.PostingOverview--errorMessage {
    cursor: pointer;
    transition: 0.15s ease-in-out all;
}

.PostingOverview--errorMessage:hover {
    filter: brightness(1.01);
}

.PostingOverview--errorList .ui.message {
    margin-bottom: 1.5rem !important;
}

.PostingOverview .ui.error.message .header + .content {
    font-size: 0.95em;
    margin: 3px 0 8px;
}

.PostingOverview--loadingMessage .ui.icon.message>.icon:not(.close) {
    font-size: 2em;
}