.FeedbackDetail {
	margin: 1rem 1rem 0 0;
}

.FeedbackDetail--header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.2rem;
}

span.FeedbackDetail--status {
	display: block;
	line-height: 1;
	background: rgba(40, 143, 219, 0.83);
	color: #fff;
	font-weight: 500;
	letter-spacing: 0.03em;
	text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
	font-size: 0.85rem;
	padding: 3px 8px;
	border-radius: 2px;
}

span.FeedbackDetail--status.rejected {
	background: rgba(219, 40, 40, 0.83);
}

span.FeedbackDetail--status.approved {
	background: rgba(50, 183, 43, 0.83);
}

.FeedbackDetail--rating {
	margin: 0 0 0.5rem;
	color: #eab512;
}

.FeedbackDetail--rating i.star.icon {
	opacity: 1 !important;
    color: #ffb70a;
    cursor: default !important;
}