.ReportingPresentationTemplates--search {
    text-align: right;
    /* width: 100%; */
    margin: 0 0 0 auto;
}

.ReportPresentationOrgList--item {
    display: flex !important;
    align-items: center;
    padding-left: 1rem !important;
}


.content.ReportPresentationOrgList--actions {
    flex: 1;
    padding-right: 1rem;
    min-width: 700px;
}

.ReportPresentationOrgList--description.description {
    display: flex !important;
    justify-content: space-between;
    padding-right: 2rem;
}

.ReportPresentationOrgList--description__right {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: center;
    opacity: 1;
    transition: 0.1s ease-in-out all;
}

.ReportPresentationOrgList--item:hover .ReportPresentationOrgList--description__right {
    opacity: 1;
}

.ReportPresentationOrgList--description__right > div,
.ReportPresentationOrgList--description__right > button,
.ReportPresentationOrgList--description__right > span {
    margin-left: 1.5rem;
}

.ReportPresentationOrgList--item__action {
    font-weight: 600;
}

.ReportPresentationOrgList--item__action.edit {
    color: #4e82e4;
}

.ReportPresentationOrgList--item__action.create {
    color: #15b315;
}

.ReportPresentationOrgList--header {
    margin: 0px 0 4px !important;
}

.ReportPresentationOrgList--item__icon {
    margin-right: 0.5rem !important;
}

.ReportPresentationOrgList--menu {
    justify-content: flex-end;
}

.ui.list>.ReportPresentationOrgList--item.item:first-child {
    padding-top: .85714286em;
}

.ReportPresentationOrgList--menu .item span {
    font-weight: 600;
    color: #555;
    margin-left: 2px;
}

.ReportPresentationOrgList--description__left span {
    font-size: 0.9rem;
}