.OnboadingChecklists {
  min-height: 80vh;
}

.item.OnboadingChecklists__item.selected {
  background: #efefef !important;
}

.item.OnboadingChecklists__item.selected i.icon {
  color: #46869e !important;
}

.OnboadingChecklists--sidebar {
  overflow-y: auto;
  height: 90vh;
  box-shadow: 0px 0px 1px 0px hsla(0, 0%, 0%, 0.85),
    0px 2px 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 1rem;
}

.item.OnboadingChecklists__item {
  margin: 0.8rem 0 !important;
}

.OnboadingChecklists--list {
  min-height: 50vh;
}

.OnboadingChecklists--loader {
  top: 200% !important;
}

.item.OnboadingChecklists__item {
  border: 2px solid transparent;
}

.item.OnboadingChecklists__item.noItem {
  border: 2px dashed #dedede;
}

.item.OnboadingChecklists__item.noItem .header {
  color: rgba(0, 0, 0, 0.6) !important;
}