
.CommentDetail--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0.5rem;
}

.CommentDetail--header .ui.header {
    margin-bottom: 0;
}
 
span.CommentDetail--header__date {
    color: #575757;
    font-size: 0.9rem;
}