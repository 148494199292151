.UserFeedback {
    position: relative;
}

.UserFeedback--button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1.5rem;
    bottom: 1rem;
    /* height: 40px;
    width: 40px; */
    background: rgba(7, 177, 220, 0.7);
    border-radius: 50%;
    box-shadow: 0px 10px 28px -7px rgba(5, 31, 30, 0.35);
    cursor: pointer;
}

.UserFeedback--button:hover {
    filter: brightness(110%);
}

.UserFeedback--button.minimized {
    background: #e03897;
    border-radius: 6px 6px 0 0;
    bottom: 0;
    padding: 0px 7px;
    font-size: 0.8rem;
    color: #fff;
    font-weight: 600;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

span.UserFeedback--minimizedPlus {
    margin-left: 3px;
    font-size: 1rem;
    font-weight: 600;
}

.UserFeedback--button i.icon {
    margin: 0;
}

.UserFeedback--choice {
    height: 100%;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.UserFeedback--column {
    cursor: pointer;
    text-align: center;
}

.UserFeedback--column:hover {
   filter: brightness(110%);
}

.UserFeedback--column .ui.header {
    margin: 0 !important;
}

.UserFeedback--minimizeButton {
    position: absolute;
    right: 1.3rem;
    font-weight: 600;
    text-align: center;
    bottom: 5rem;
    height: 8px;
    width: 14px;
    line-height: 5px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    display: inline-flex;
    justify-content: center;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.15s ease-in-out all;
}

.UserFeedback--minimizeButton:hover {
    background: rgba(0, 0, 0, 0.75);
}
