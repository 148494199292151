iframe.ScheduleMeetingButton--iframe {
    min-width: 600px;
    border: none;
    border-radius: 4px;
    min-height: 600px;
}

.ScheduleMeetingButton--helperText p {
    font-weight: 600;
    color: #444;
    letter-spacing: 0.01em;
    margin: 0 0 1.5rem;
}

.ScheduleMeetingButton {
    display: inline-block;
}

.ScheduleMeetingButton--popup .ContentPopup--content {
    width: 600px;
}