.WashingtonRedskins {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.DashBoard {
  min-height: 95vh;
  width: 100%;
  height: 100%;
  padding: 1.5rem 3rem;
  position: relative;
}

.DashBoard--content {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.DashBoard--content__intro {
  margin: 1.5rem 0 0;
}

.DashBoard--header {
  color: rgba(255, 255, 255, 0.82) !important;
  font-size: 2.4rem !important;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.DashBoard--copy {
  color: #5e6d89;
  font-size: 1.1rem;
}

.DashBoard--content__grid {
  margin: 3rem 0;
}

.DashBoard--content__grid--item {
  background: #fff;
  margin: 0 0 1rem;
  box-shadow: 0px 10px 28px -7px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 1.5rem 3rem;
  border-top: 2px solid rgba(6, 192, 238, 0.55);
  transition: 0.15s ease-in-out all;
  cursor: pointer;
}

.DashBoard--content__grid--item:hover {
  transform: translateY(-1px);
  box-shadow: 0px 12px 32px -5px rgba(0, 0, 0, 0.3);
}

.DashBoard--content__grid--item .ui.header {
  color: #32325d !important;
  margin: 0 0 1.5rem !important;
  text-align: center;
}

.DashBoard--content__grid--item img {
  display: block;
  height: 100%;
  width: 100%;
  max-height: 150px;
}

.stripes {
  /* display: grid; */
  top: 0;
  height: 100%;
  left: 0;
  position: absolute;
  z-index: -1;
  width: 100%;
  grid: repeat(5, 200px) / repeat(10, 1fr);
  transform: skewY(-12deg);
  transform-origin: 0;
  background: linear-gradient(
    #507eb1,
    #709dc7 10%,
    #dde9f5 38%,
    #eaf2f9 48%,
    #f6f9fc 62%
  );
}

.stripes :nth-child(1) {
  grid-column: span 3;
  background: linear-gradient(
    100grad,
    rgba(243, 251, 255, 0.4),
    rgba(243, 251, 255, 0.15) 20%,
    rgba(243, 251, 255, 0)
  );
}

.stripes :nth-child(2) {
  grid-area: 3 / span 3 / auto / -1;
  background: linear-gradient(
    100grad,
    rgba(0, 119, 204, 0),
    rgba(0, 119, 204, 0.05) 40%,
    rgba(0, 119, 204, 0.35)
  );
}

.stripes :nth-child(3) {
  grid-row: 4;
  grid-column: span 5;
  background: linear-gradient(100grad, #fff 50%, hsla(0, 0%, 100%, 0));
}

.stripes :nth-child(4) {
  grid-area: 4 / span 5 / auto/-1;
  background: linear-gradient(
    100grad,
    rgba(0, 119, 204, 0) 10%,
    rgba(0, 119, 204, 0.05)
  );
}

.stripes :nth-child(4) {
  grid-area: auto/1/-1/-1;
  background: linear-gradient(100grad, #fff 80%, #f5fafd);
}
