/* .item.ChecklistItem.isHovering:after {
    position: absolute;
    content: '';
    height: 20px;
    bottom: 5.5px;
    right: 1px;
    width: 2px;
    background: #06bfee;
    border-radius: 10rem;
} */

.content.ChecklistItemContent {
    display: flex;
    align-items: center;
    padding: 3px 5px;
}

.ChecklistItemContent--main {
    flex: 1;
}

.ChecklistItem .ChecklistItemContent:hover{
    background: #f7f7f7;
}

.ChecklistItem .ChecklistItemContent {
    /* background: #eee; */
    border: 1px solid transparent;
    border-radius: 6px;
    transition: background .1s ease-in;
    /* transition: background 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) */
}

.ChecklistItem.isTransitioning .ChecklistItemContent {
    /* background: rgba(183, 234, 192, 0.3); */
    border: 1px solid rgba(183, 234, 192, 0.7);
    background: rgba(183, 234, 192, 0.4);
    background: -webkit-radial-gradient(center, rgba(183, 234, 192, 0.4), rgba(183, 234, 192, 0.3));
    background: -moz-radial-gradient(center, rgba(183, 234, 192, 0.4), rgba(183, 234, 192, 0.3));
    background: radial-gradient(ellipse at center, rgba(183, 234, 192, 0.4), rgba(183, 234, 192, 0.3));
}


.ChecklistItem__header input,
.ChecklistItemContent--readOnly {
    font-size: 1rem !important;
    font-weight: 600 !important;
}

.ChecklistItemContent--right {
    display: flex;
    align-items: center;
}

.ChecklistItemContent--right > * {
    margin: 0 7px;
}

.ChecklistItemContent--left {
    /* margin-right: 0.5rem; */
    width: 25px;
}

.ChecklistItem .hoverOnly {
    display: none;
}

.ChecklistItem:hover .hoverOnly {
    display: inherit;
}

.ChecklistItem--delete {
    cursor: pointer;
    transition: .12s ease all;
}

.ChecklistItem--delete:hover {
    filter: brightness(115%);
}

.ChecklistItem--children{
    /* border-bottom: 1px solid rgba(34,36,38,.15) !important; */
    font-size: 0.9rem;
    padding: .75em 0 .25em 1.5em !important;

}

.ChecklistItem--children .ChecklistItem .ChecklistItem__header input,
.ChecklistItem--children .ChecklistItem .ChecklistItemContent--readOnly  {
    font-size: 0.9rem !important;
}

.ui.ordered.list .list>.item.ChecklistItem :before, 
.ui.ordered.list>.item.ChecklistItem :before, 
.ui.ordered.list .Checklist ol.ui.list li:before,
.item.ChecklistItem:before {
    margin-top: .7rem !important;
}

.ui.ordered.list .list>.item.ChecklistItem:before, 
.ChecklistItem--children ol.ui.list ol li:before {
    margin-top: .75rem !important;
}

.arrowControls {
    display: flex;
    flex-direction: column;
    color: #71b59a;
    font-size: 0.9rem;
    line-height: 1;
}

.arrowControl {
    cursor: pointer;
}

.arrowControl:hover {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
    color: #10d221;
}

.ChecklistItemContent--checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
    border: 1px solid #d2d2d2;
    border-radius: 50%;
    font-size: 0.7rem;
    line-height: 20px;
    transition: .1s ease all;
    cursor: pointer;
}

.ChecklistItemContent--checkbox:hover {
    border: 1px solid #5cdc73;
    background: rgba(92, 220, 115, 0.12);
}

.ChecklistItemContent--checkbox i.icon {
    margin: 0;
    width: auto;
    height: auto;
    color: #808080;
    transition: .1s ease all;
}

.ChecklistItemContent--checkbox:hover i.icon {
    color: #5cdc73;
}

.ChecklistItem.completed .ChecklistItemContent--checkbox {
    border: 1px solid #5cdc73;
    background: #5cdc73;
}

.ChecklistItem.completed .ChecklistItemContent--checkbox i.icon {
    color: #fff;
}

.ChecklistItem--dueDate {
    color: #5a5a5a;
    font-size: 0.85rem;
    /* width: 75px; */
}

.ChecklistItem--dueDate span {
    font-weight: 600;
}

.ChecklistItem--dueDate__date.late {
    color: #ff3434;
}

.ChecklistItem--dueDate__date.today {
    color: #1eb905;
}