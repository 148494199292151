/* .item.ChecklistTemplateItem.isHovering:after {
    position: absolute;
    content: '';
    height: 20px;
    bottom: 5.5px;
    right: 1px;
    width: 2px;
    background: #06bfee;
    border-radius: 10rem;
} */
.content.ChecklistTemplateItemContent {
    display: flex;
    align-items: center;
    padding: 4px 5px 0px;
}

.ChecklistTemplateItemContent--main {
    flex: 1;
}


/* .item.ChecklistTemplateItemContent:hover {

} */

.ChecklistTemplateItem__header input {
    font-size: 1rem !important;
    font-weight: 600 !important;
}

.ChecklistTemplateItemContent--right {
    display: flex;
    align-items: center;
}

.ChecklistTemplateItemContent--right > * {
    margin: 0 7px;
}

.ChecklistTemplateItem .hoverOnly {
    display: none;
}

.ChecklistTemplateItem:hover .hoverOnly {
    display: inherit;
}

.ChecklistTemplateItem--delete {
    cursor: pointer;
    transition: .12s ease all;
}

.ChecklistTemplateItem--delete:hover {
    filter: brightness(115%);
}

.CheckListTemplateItem--children{
    /* border-bottom: 1px solid rgba(34,36,38,.15) !important; */
    font-size: 0.9rem;
    padding: .75em 0 .25em 1.5em !important;

}

.CheckListTemplateItem--children .ChecklistTemplateItem .ChecklistTemplateItem__header input {
    font-size: 0.9rem !important;
}

.ui.ordered.list .list>.item.ChecklistTemplateItem :before, 
.ui.ordered.list>.item.ChecklistTemplateItem :before, 
.ui.ordered.list .ChecklistTemplate ol.ui.list li:before,
.item.ChecklistTemplateItem:before {
    margin-top: .7rem !important;
}

.ui.ordered.list .list>.item.ChecklistTemplateItem:before, 
.CheckListTemplateItem--children ol.ui.list ol li:before {
    margin-top: .75rem !important;
}

.arrowControls {
    display: flex;
    flex-direction: column;
    color: #71b59a;
    font-size: 0.9rem;
    line-height: 1;
}

.arrowControl {
    cursor: pointer;
}

.arrowControl:hover {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
    color: #10d221;
}