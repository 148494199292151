.ReportSettings--accountTableContainer {
    display: block;
    max-width: 700px;
}

.ReportSettings .isUpdating {
    position: relative;
    overflow: hidden;
}

.ReportSettings .isUpdating:before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 600%;
    background: rgba(238, 238, 238, 0.5);
    background: #EEEEEE4D;
    background: -webkit-linear-gradient(left, #EEEEEE4D, #EEEEEE99);
    background: -moz-linear-gradient(left, #EEEEEE4D, #EEEEEE99);
    background: linear-gradient(to right, #EEEEEE4D, #EEEEEE99);
    animation: 1.1s loading-placeholder cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
}

.field.ReportSettings--submitButton {
    display: flex;
    align-items: flex-end;
}

section.ReportSettings--addUser {
    margin: 1.5rem 0 1rem;
}

.ReportSettings--accountTable i {
    color: #aba7a7;
    margin-left: 0.4rem !important;
}