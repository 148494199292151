.clientMessage {
    font-weight: 600;
    padding-bottom: 1rem;
}

.ClientContentDashboard .AssignmentList {
    padding-top: 2rem;
    height: 100%;
}

.ClientContentDashboard .ArticleDetail {
    position: fixed;
    height: 100%;
    padding-right: 2rem;
}

.ClientContentDashboard .FormHeader {
    position: relative;
    padding: 0rem 0 0rem;
}

.ClientContentDashboard--archive-link {
    display: block;
    margin: 1rem 2px;
}

.ClientContentDashboard--archive-link a {
    font-size: 1.1rem;
    font-weight: 600;
}