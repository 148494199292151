.OrganizationDetail {
    border-radius: 3px !important;
    height: 100%;
}

.OrganizationHeader {
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: space-between;
}

.AddressInfo {
    display: flex;
    flex-direction: column;
}

.AddressNumbers {
    display: flex;
    flex-direction: column;
}

.ui.header .sub.header {
    margin: 0.3rem 0 0 !important;
}

strong {
    margin: 0 0 0.2rem;
}

.OrganizationHeader.UserOrganizationDetail {
    justify-content: flex-start;
    margin-bottom: 2rem;
}

.OrganizationHeader.UserOrganizationDetail .ui.header {
    margin: 0 0 0 1rem;
}

.DirectoryOrgHeadline {
    margin-bottom: 2.5rem !important;
}

.OrganizationDetailContent {
    padding: 0 1rem;
}

.OrganizationDetail--Subheader {
    display: flex;
    flex-direction: column;
}

.OrganizationDetail--Subheader__item {
    margin: 0 0 0.4rem;
}