

.PostingOverviewDetails .PostingOverviewDetailsBlock {
    margin: 1rem 0;
    padding: 0.3rem 0;
}

.PostingOverviewDetails .PostingOverviewDetailsBlock:first-child {
    margin-top: 0;
    padding-top: 0;
}

h4.ui.header.PostingOverviewDetailsBlock--header {
    margin: 0 0 0.5rem;
}

.PostingOverviewDetailsBlock .ui.table tbody tr {
    font-size: 0.8rem;
}

.PostingOverviewDetailsBlock--content .ui.table.basic {
    background: #fff;
}