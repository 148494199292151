.PDFView iframe {
    min-width: 650px;
}

.ContentPopup--content .PDFView--content {
    padding: 1rem;
    /* overflow-y: scroll; */
}

.ContentPopup--content .PDFView--page {
    border: 1px solid #eee;
    margin: 1rem 0 1.5rem;
    padding: 0.8rem 1rem;
    box-shadow: 0px 2px 4px -3px rgba(0, 0, 0, 0.2);
}

.ContentPopup--content .PDFView-pageSection {
    display: block;
    padding: 0.7rem 0;
}

.ContentPopup--content .PDFView--page:first-of-type {
    margin-top: 0.3rem;
}

.PDFView--Actions {
    display: flex;
    align-items: center;
}

.PDFView--disclaimer {
    font-size: 0.7rem;
    color: #777;
    margin-right: 2.5rem;
    line-height: 1;
    flex: 1;
    font-weight: 600;
}