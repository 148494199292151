.AccountHealthOverviewList--headerArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 0 0 1.5rem; */
    margin: 0 0 2.2rem;
}

.AccountHealthOverviewList--search {
    margin-right: 0.5rem;
}

.AccountHealthOverviewList--search .ui.search .ui.input {
    width: 275px;
}

.AccountHealthOverviewList--sortBy .divider {
    margin-top: 0 !important;
}

.item.AccountHealthOverviewList-sortDropdownOption i.ascending {
    color: #13b39e;
}

.item.AccountHealthOverviewList-sortDropdownOption i.descending {
    color: #4357bf;
}

.AccountHealthOverviewList--sortBy {
    font-weight: 600;
}

.AccountHealthOverviewList--headerAreaHalf {
    display: flex;
    align-items: center;
}

.AccountHealthOverviewList--headerAreaHalf > *:not(:first-child) {
    margin-left: 1rem;
}

.AccountHealthOverviewList--noData {
    display: block;
    margin: 6rem auto 0;
    text-align: center;
}

.AccountHealthOverviewList--noData .ui.header {
    display: inline-block;
    text-align: left !important;
}

span.AccountHealthOverviewList--clearSearch {
    font-weight: 900;
    color: #19bcb4;
    color: #2085d0;
    font-size: 1.05rem;
    letter-spacing: 0.01em;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    margin: 0 2px;
    transition: 0.12s ease color;
}

span.AccountHealthOverviewList--clearSearch:hover {
    color: #419ce1;
}

.AccountHealthOverviewList .ui.toggle.checkbox label {
    font-size: 0.95rem;
}

.AccountHealthOverviewList .ui.toggle.checkbox input:checked~.box:before, 
.AccountHealthOverviewList .ui.toggle.checkbox input:checked~label:before {
    background-color: rgba(33, 133, 208, 0.85)!important;
}