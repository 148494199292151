.ClientReportingTable {
    margin: 1.75rem 0 2rem;
}

.ClientReportingTable--orgHeader {
    font-size: 1rem !important;
    margin: 0 !important;
}

.ClientReportingTable--reportLinks span {
    margin-right: 0.35rem !important;
}