/* .PresentationCreator--slideTab {
    min-height: 45px;
} */

a.active.item.PresentationCreator--slideTab span {
    font-weight: 600;
}

.PresentationCreator--slideTabActions {
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

 .PresentationCreator--cancelButton{
    padding: .3em !important;
    margin-right: 0 !important;
    margin-left: 5px;
}

.item.PresentationCreator--slideTab:hover .PresentationCreator--slideTabActions {
    opacity: 1;
    visibility: visible;
    pointer-events: inherit;
}

.PresentationCreator--slideTabActions__move button {
    padding: .3em 0.4em !important;
}

.PresentationCreator--slideTab__icon i {
    display: block;
    margin: 0;
}

span.PresentationCreator--slideTab__icon i {
    margin: 0;
}

a.item.PresentationCreator--slideTab {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

a.item.PresentationCreator--addSlide {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

span.PresentationCreator--addSlide__icon {
    margin-left: 5px;
}

span.PresentationCreator--addSlide__icon i {
    display: block;
    margin: 0;
}


.PresentationCreator--title-input {
    min-width: 600px;
}

.PresentationCreator--main-menu {
    margin: 2rem 0 2rem;
}

.PresentationCreator--main-menu .link.item {
    padding: 1em 1.5em;
}

.PresentationCreator--main-menu .link.item i {
    margin-right: 0.6rem !important;
}

.PresentationCreator--main-menu .link.item.active {
    font-weight: 600;
}