.DayWeekPickerWidget.hasValue {
    min-width: 90px;
}

.calendarCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    border: 1px dashed #c5c5c5;
    line-height: 27px;
    cursor: pointer;
}

.calendarCircle i.icon {
    margin: 0;
    width: auto;
    height: auto;
    color: #c5c5c5;
    transition: .12s ease all;
}

.calendarCircle:hover {
    border: 1px solid #484848;
}

.calendarCircle:hover i.icon {
    color: #484848;
}

.DayWeekPickerWidget--value {
    display: flex;
    cursor: pointer;
    font-size: 0.8rem;
    color: #696969;
}


.DayWeekPickerWidget--value__week,
.DayWeekPickerWidget--value__day {
    font-weight: 600;
}

.DayWeekPickerWidget--value__week {
    margin-left: 1px;
}

.DayWeekPickerWidget--value__day {
    margin-left: 5px;
}