.NotificationStack {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
}

.NotificationCard {
    position: absolute;
    background: #fff;
    border-top: 3px solid #00bee6;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.75), 0px 2px 3px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 0.8rem 1.5rem;
    width: 300px;
    left: 250px;
    bottom: 30px;
    margin: 0;
    z-index: 99;
}

.NotificationCard--heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0.8rem;
}

.NotificationCard--header {
    margin: 0 !important;
    color: #444 !important;
}

.NotificationCard--close {
    font-weight: 700;
    display: block;
    cursor: pointer;
    font-size: 1.2rem;
    color: #797979;
}