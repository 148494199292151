.ReportPresentationOrgList--item.isHandlingAction {
    opacity: 0.6;
}

.ReportPresentationOrgList--item.isHandlingAction a {
    cursor: not-allowed !important;
    pointer-events: none;
}

.ReportPresentationOrgList--loader {
    position: absolute !important;
    top: 32% !important;
    left: 50% !important;
}

.ReportPresentationOrgList--item {
    position: relative;
}

span.ReportPresentationOrgList--currentReportLinkText {
    color: #3492d5;
    font-size: 0.85rem;
    font-weight: 600;
}

.ReportPresentationOrgList--currentReportLinkTextInfo {
    color: #424242;
    font-weight: 500;
    font-size: .8rem !important;
}

.ReportPresentationOrgList--missingDataContainer .label.tiny {
    padding: .4em .833em .5em !important;
}