
.AccountOverviewFilter .inline.field label {
    width: 50px !important;
}

.AccountOverviewFilter .field .ui.button {
    margin-left: calc(50px + .85714286em)!important;
}



.ui.dropdown .menu>.item.item.AccountOverviewFilter--contentContainer:hover {
    background: #fff;
    color: inherit;
}