.ContentGeneration {
    width: 100%;
    padding: 1.5rem 2rem;
    overflow-y: auto;
    max-height: 90vh;
    min-height: 65vh;
}

.sidebarActions {
    margin: 0 0 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebarActions .ui.header {
    margin: 0;
}

.sidebarFormContainer {
    overflow-y: auto;
    height: 100%;
    max-height: 85vh;
    padding: 0 1rem 0 0.1rem;
    margin-top: 7rem;
    /* border-bottom: 1px inset rgba(0, 0, 0, 0.11); */
}