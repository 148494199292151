.WebsiteBuildCard--wrapper {
  margin: 2rem 0;
}

.WebsiteBuildCard.archives {
  border-top: 2px solid #ff8787 !important;
}

.WebsiteBuildCard .description.WebsiteBuildCard--description__container {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.WebsiteBuildCard--description__item {
  flex-grow: 0;
  flex-basis: 33%;
  margin-bottom: 1rem;
}

.WebsiteBuildCard--description__item a {
  display: block;
  font-size: 0.9rem;
}

.WebsiteBuildCard--checklistStatuses {
  display: flex;
  /* margin: 0.9rem 0 0; */
}

.WebsiteBuildCard--checklistStatus {
  margin-right: 2rem;
  font-size: 0.9rem;
  border: 1px solid #d2d2d2;
  padding: 3px 12px;
  border-radius: 3px;
  box-shadow: 0px 3px 7px -2px rgba(0, 0, 0, 0.12);
  color: #696969;
  line-height: 1;
  cursor: pointer;
  min-width: 150px;
  transition: 0.25s ease all;
}

.WebsiteBuildCard--checklistStatus:hover {
  transform: translateY(-1px);
  border: 1px solid #d8d8d8;
  box-shadow: 0px 3px 7px -1px rgba(0, 0, 0, 0.18);
}

.WebsiteBuildCard--checklistStatus__content {
  display: flex;
  align-items: center;
}

.WebsiteBuildCard--checklistStatus__content span {
  margin-right: 7px;
}

.WebsiteBuildCard--description {
  font-size: 0.85rem;
  color: #7d7d7d;
}

.WebsiteBuildCard--checklistStatus__content i.icon {
  margin-right: 0 !important;
}

.WebsiteBuildCard .description:not(:last-child) {
  margin-bottom: 0.5rem;
}
