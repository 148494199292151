.OrganizationList {
    margin: 1rem 0;
}

.OrganizationListSearch {
    margin: 0 0 2rem;
    width: 300px;
}

.OrganizationListSearch .ui.input {
    display: flex !important;
}

.OrgListItem .OrgListContent {
    transition: 0.17s ease-in-out all;
}

.OrgListItem:hover .OrgListContent {
    transform: translateX(3px);
}

.OrganizationList--list {
    overflow-y: auto;
    max-height: 100vh;
}