.ArticleHistoryChart--headerArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem;
    /* padding: 0 1.5rem; */
}

.ArticleHistoryChart--headerArea .ui.header {
    margin: 0;
}

.ArticleHistoryChart .recharts-responsive-container {
    overflow-x: visible;
}

.ArticleHistoryChart svg.recharts-surface {
    overflow: visible;
}


.ArticleHistoryChart--insights {
    display: block;
    /* padding: 0 2rem 0 1.5rem; */
    padding: 0 1rem;
    margin: 0 0 1rem;
}

.ArticleHistoryChartFigure .ui.segment {
    padding: 0.75rem 1rem;
}

.ArticleHistoryChart--chart {
    /* padding: 1.5rem 1.5rem 2rem; */
    padding: 1rem;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 4px;
}

.ArticleHistoryChart--insights .ui.grid>.row>.column {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.ArticleHistoryChart--insights .ui.grid>.row>.column:first-child {
    padding-left: 0;
}

.ArticleHistoryChart--insights .ui.grid>.row>.column:last-child {
    padding-right: 0;
}

.ArticleHistoryChart--ctaArea > *:not(:last-child) {
    margin-right: 0.5rem !important;
}