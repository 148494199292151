.WebsiteBuildsMain {
    position: relative;
}

.WebsiteBuilds--addLoader {
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;
}

.WebsiteBuilds--addLoader__content {
    margin: 6rem 0 0;
    font-size: 1.1rem;
    color: #6b6b6b;
    font-weight: 600;
    background: #fff;
    border-radius: 4px;
    border: 2px solid #e8e8e8;
    padding: 2.5rem 3.5rem;
    box-shadow: 0px 7px 22px -9px rgba(0, 0, 0, 0.1);
}

.bounceLoader {
    /* animation: bounce 1.5s cubic-bezier(.5,0.05,1,.5); */
    animation: bounce 1.2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  margin-left: 5px !important;
}

@keyframes bounce {
    from { transform: translate3d(0, 0, 0);     }
    to   { transform: translate3d(0, 15px, 0); }
  }