

.UserDataWriterDetails__header {
    display: block;
    font-weight: 600;
    font-size: 1.1rem;
    color: #373737;
    margin: 0 0 0.5rem;
}

.UserDataWriterDetailsItem {
    margin: 0 0 0.8rem;
}

.UserDataWriterDetailsItem__label {
    display: block;
    font-size: 0.9rem;
    color: #797979;
}

.UserDataWriterDetailsItem__content {
    display: block;
    font-weight: 600;
}