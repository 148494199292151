.ui.header.WebsiteBuildList--noContentHeader {
    margin-top: 3rem !important;
    text-align: center;
}

.WebsiteBuildList {
    min-height: 50vh;
    max-height: 85vh;
    overflow-y: auto;
    padding: 1rem 2rem 1rem 1rem;
}