.StatusPill {
    color: #fff;
    border-radius: 8px;
    font-size: 12px;
    height: 16px;
    white-space: nowrap;
    line-height: 16px;
    font-weight: 400;
    padding: 0 8px 0px;
    display: inline-block;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}