.ContentOverview {
    display: block;
    min-height: 150px;
    width: 100%;
}

.ContentOverview--header {
    text-align: left;
}

h3.ui.header.ContentOverview--header {
    font-size: 1.35rem;
}

.ContentOverview--header__currentMonth span,
.ContentOverview--header__monthsBack span {
    margin-right: 3px;
}

.PDFView--content .ContentOverview--table {
    font-size: 0.85rem;
}

.PDFView--content .ui.table td {
    padding: .58571429em .78571429em;
    line-height: 1.2;
}

.PDFView--content .ui.table thead th {
    line-height: 1;
}