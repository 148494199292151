.PresentationCreatorComponent {
    margin: 0 0 2rem;
    box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.08);
}

.PresentationCreatorComponent--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PresentationCreatorComponent--cancelButton {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    cursor: pointer;
    padding: .3em !important;
    margin-right: 0 !important;
    transition: 0.12s ease-in-out all;
}

.PresentationCreatorComponent:hover .PresentationCreatorComponent--cancelButton {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}

.PresentationCreatorComponent .ui.header {
    border-top: 2px solid rgba(4, 192, 237, 0.75) !important;
}

.PresentationCreatorComponent .ui.header.invalid {
    border-top: 2px solid rgba(239, 94, 94, 0.75) !important;
}

.PresentationCreatorComponent--optionalFieldsActions {
    display: flex;
    justify-content: flex-end;
}

.PresentationCreatorComponent--optionaFieldsOutput {
    margin: 1rem 0 0;
}

.PresentationCreatorComponent--requiredFields:not(:empty) {
    margin-bottom: 1rem;
}

.PresentationCreatorComponent--header__action .message {
    margin: 0 1rem 0 0 !important;
    padding: 0.3em 1.5em !important;
}

.PresentationCreatorComponent--header__action {
    display: flex;
    align-items: center;
}