/* .AppCreateForm--childThemeOptions .item {
    border-top: 1px solid #000;
} */

.item.text.AppCreateForm--childThemeOption {
    text-align: center;
    padding: 1rem 0;
}

.item.text.AppCreateForm--childThemeOption .content {
    /* flex: 1; */
    display: block;
    text-align: center;
    font-size: 1.2rem;
    margin: 0 0 1rem;
    color: #545454;
}

.item.text.AppCreateForm--childThemeOption .image {
    /* flex: 2; */
    box-shadow: 0px 10px 22px -5px rgba(0, 0, 0, 0.2);
}

.AppCreateForm--initializeLoader {
    display: flex;
    justify-content: center;
}