.ActionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1.3rem;
}

.ActionHeader .ui.header {
    margin: 0 !important;
}

.ActionHeaderActions .dropdown {
    margin-right: 1rem;
}

.ActionHeaderActions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.ActionHeaderActions > div,
.ActionHeaderActions > button,
.ActionHeaderActions > a {
    margin-left: 0.4rem !important;
}

.ActionHeader.ActionHeader-with-button {
    justify-content: flex-start;
}

.ActionHeader.ActionHeader-with-button .ActionHeaderActions {
    margin-left: 1rem;
}

.ActionHeader .ui.header .content {
    display: flex;
    align-items: center;
    line-height: 1;
}