.UserProfile__UsernameSection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.UserProfile__Photo {
    margin: 0 1rem 0 0;
}

.UserProfileItem {
    margin: .5rem 0 .5rem 0.5rem;
    padding: .5rem 0;
    font-size: 1.1rem;
}

.UserProfileItem.isEditable {
    cursor: pointer;
}

.UserProfileItem.isEditable:hover {
    cursor: pointer;
}

.UserProfileItem strong {
    color: #525252;
}

.UserProfileItem span {
    margin-left: 5px;
}