.MainContent .pushable:not(body) {
    -webkit-transform: none;
    transform: none;
}

.ui.small.negative.message.mobileDeviceMessage {
    margin: 0.6rem !important;
}

.MainContentOutput {
    display: block;
    width: 100%;
    height: 100%;
}