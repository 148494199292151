.ContentPopup--body {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 10px 28px -7px rgba(0, 0, 0, 0.32);
}

.ContentPopup--content {
    overflow-y: auto;
    width: 700px;
    height: 600px;
}

.ContentPopup--content iframe {
    /* min-width: 600px; */
    border: none;
    min-height: 600px;
}

.ContentPopup--headerArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0.6rem 1rem;
    border-bottom: 1px solid #e0e1e2;
    border-radius: 4px 4px 0 0;
}

h3.ui.header.ContentPopup--header {
    margin: 0;
    color: #444;
}