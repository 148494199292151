.ReportDetail--items {
    margin: 1rem 0;
}

.ReportDetail--item {
    padding: 7px 0;
    display: flex;
    flex-direction: column;
}

span.ReportDetail--item__detail {
    font-weight: 600;
    margin-top: 2px;
    color: #4e4d4d;
    display: flex;
    align-items: center;
}

span.ReportDetail--item__detail span {
    margin-right: 0.5rem;
}

.ui.selection.dropdown.ReportDetail--status {
    width: 40%;
    margin-top: 6px;
}

.ui.input.ReportDetail--item--url {
    width: 80%;
    margin-top: 6px;
}

.ReportDetail--item__inlineField {
    display: flex;
}

.ReportDetail--item__inlineField .ReportDetail--item__detail {
    margin-left: 1rem;
}