.AppManagementDirectory--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AppManagementDirectory--appForm {
    margin-top: 2rem;
    background: #fff;
    padding: 1.3rem 2rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border-left: 2px solid #06bae6;
}

.AppManagementDirectory--searchBox {
    display: flex;
    align-items: center;
    min-width: 200px;
    width: 275px;
}

.AppManagementDirectory--searchBox i {
    color: #9a9a9a!important;
}


.AppManagementDirectory--searchInput {
    flex: 1;
    border-bottom: 1px solid #d0d0d0 !important;
    padding-bottom: 3px;
    margin-left: 5px;
}