.ReportingMonthTable-createActions {
    display: inline-flex;
    align-items: center;
}

.ReportingMonthTable--status .ui.selection.dropdown {
    min-width: 10em;
}

.ReportEntries--sidebarActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ReportingMonthTable-createActions > *:not(:last-child) {
    margin-right: 8px !important;
}