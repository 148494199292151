.ChecklistMessage {
    min-width: 200px;
    z-index: 1000;
}

.ChecklistMessage .ui.message {
    padding: 0.25em 1.5em;
}

.ChecklistMessage .icon {
    font-size: inherit !important;
}

.ChecklistMessage--content {
    flex: 1;
}