.ProgressCircle {
    display: inline-flex;
}

.radialProgressBar {
    display: inline-flex;
	border-radius: 50%;
	background: #ddd;
}

.radialProgressBar .overlay {
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: #fff;
    text-align: center;
}