.StarRating {
    display: inline-block;
    position: relative;
    /* height: 20px;
    line-height: 20px;
    font-size: 20px; */
  }
  
  .StarRating label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
  }
  
  .StarRating label:last-child {
    position: static;
  }
  
  .StarRating label:nth-child(1) {
    z-index: 5;
  }
  
  .StarRating label:nth-child(2) {
    z-index: 4;
  }
  
  .StarRating label:nth-child(3) {
    z-index: 3;
  }
  
  .StarRating label:nth-child(4) {
    z-index: 2;
  }
  
  .StarRating label:nth-child(5) {
    z-index: 1;
  }
  
  .StarRating label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  
  .StarRating label .icon  {
    float: left;
    color: transparent !important;
  }
  
  .StarRating label:last-child .icon,
  .StarRating label:last-child .icon .icon {
    color: #d6d6d6 !important;
  }
  
  .StarRating:not(:hover) label input:checked ~ .icon,
  .StarRating:not(:hover) label input:checked ~ .icon .icon,
  .StarRating:hover label:hover input ~ .icon,
  .StarRating:hover label:hover input ~ .icon .icon  {
    color: #eab512 !important;
  }
  
  .StarRating label input:focus:not(:checked) ~ .icon:last-child,
  .StarRating label input:focus:not(:checked) ~ .icon:last-child .icon {
    color: #000 !important;
    text-shadow: 0 0 5px #eab512 !important;
  }