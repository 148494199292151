.UpdateIndicator {
    position: fixed;
    margin: 0;
    display: block;
    text-align: right;
    font-size: 0.9rem;
    z-index: 1000;
}

span.updateIndicator__text {
    margin-right: 5px;
}

.UpdateIndicator__content {
    display: inline;
    color: #fff;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.18);
    padding: 3px 8px 4px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.75);
}