.Register {
    display: flex;
    margin: 0% auto 4rem;
    max-width: 1000px;
    min-width: 800px;
    border-radius: 4px;
    /* box-shadow: 0 0 0 1px rgba(0,0,0,.08),0 1px 4px rgba(0,0,0,.1); */
    box-shadow: 0 0 0px 1px rgba(0,0,0,.1), 0 1px 7px rgba(0,0,0,.12);
    background: #fff;
}

.RegisterForm {
    width: 100%;
    padding: 1.5rem 3rem 2.5rem;
}

.AuthSidebar {
    padding-top: 20px;
    width: 60px;
    background: #564f64;
    background-image: none;
    background-image: linear-gradient(-180deg,rgba(52,44,62,0),rgba(52,44,62,.5));
    box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
    border-radius: 4px 0 0 4px;
    margin-top: -1px;
    margin-bottom: -1px;
    text-align: center;
}