.SortableTable--Table {
    overflow-y: auto;
    max-height: 80vh;
    padding: 0 1rem 0 0;
}

.selectable tr {
    cursor: pointer;
}

tr.SortableTableRow.Selected {
    background: #f4f4f4;
    font-weight: 600;
}

.SortableTableFilters {
    display: flex;
    margin: 0 0 1rem;
    overflow-x: auto;
    overflow-y: visible;
    padding: 0 0 1rem;
    min-height: 40px;
}

.SortableTableFilters > * {
    position: absolute;
}

.SortableTableFilters > *:nth-child(2) {
    left: calc(200px + 1.5rem);
}

.SortableTableFilters > *:nth-child(3) {
    left: calc(400px + 2rem);
}

.SortableTableFilters > *:nth-child(4) {
    left: calc(600px + 2.5rem);
}

.SortableTableFilters > *:nth-child(5) {
    left: calc(800px + 3rem);
}

.SortableTableFilters > *:nth-child(6) {
    left: calc(1000px + 3.5rem);
}

.SortableTable--staffToggle {
    display: flex;
    align-items: center;
    min-height: 2.71428571em;
    margin-left: 1rem;
}

.shifting .SortableTableRow {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.SortableTableRow span.isLate {
    color: #f5143c;
}