.clientMessage {
    font-weight: 600;
    padding-bottom: 1rem;
}

.ClientArchiveDashboard .AssignmentList {
    padding-top: 2rem;
    height: 100%;
}

.ClientArchiveDashboard .ArticleDetail {
    position: fixed;
    height: 100%;
    padding-right: 2rem;
}

.ClientArchiveDashboard .FormHeader {
    position: relative;
    padding: 0rem 0 0rem;
}