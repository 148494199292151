.AppView {
  min-height: 80vh;
}

section.AppView--content {
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 3px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.03),
    0px 5px 11px -4px rgba(0, 0, 0, 0.15);
  min-height: 70vh;
  position: relative;
}

.AppView--content__section.AppView--content__body--middle {
  flex: 3;
}

.AppView--content__section.AppView--content__body--right {
  flex: 3;
}

.AppView-backups {
  padding-right: 2rem;
}

.AppView--siteLink {
  color: #779cab;
  font-size: 1.1rem;
}

.AppView--headerActions {
  margin: 0 0 1rem;
}

.AppView--siteLink i {
  margin-left: 0.4rem !important;
}

.AppView-backups__backupItem--content {
  cursor: pointer;
}

.content.AppView-backups__backupItem--content a {
  margin-left: 0.5rem;
}

.AppView--content__header {
  display: flex;
  align-items: center;
  padding: 0.4rem 0 1rem;
  border-bottom: 1px solid #cacaca;
}

.AppView--content__header--left {
  flex: 1;
}

.AppView--content__header--left .ui.header {
  color: #1c4a5d;
}

.AppView--content__header--right {
  flex: 1;
  text-align: right;
}

.AppView--content__body {
    display: flex;
    padding: 2rem 1rem;
}

.AppView--content__section  {
    flex: 2;
    padding-right: 2rem;
}

.AppView--status__item {
    margin: 6px 0;
}

.AppView--status__item__label {
    display: inline-block;
    margin-right: 5px;
    color: #4e4e4e;
    font-weight: 600;
    width: 85px;
}

.AppView--statusIndicator {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 3px;
}

.AppView--statusIndicator.up {
  background: #7ff954;
  background: -webkit-radial-gradient(center, #7ff954, #6ae041);
  background: -moz-radial-gradient(center, #7ff954, #6ae041);
  background: radial-gradient(ellipse at center, #7ff954, #6ae041);
  border: 1px solid #64ea35;
}

.AppView--statusIndicator.down {
  background: #f95b54;
  background: -webkit-radial-gradient(center, #f95b54, #e05241);
  background: -moz-radial-gradient(center, #f95b54, #e05241);
  background: radial-gradient(ellipse at center, #f95b54, #e05241);
  border: 1px solid #f95b54;
}


.AppView-backups__backupItem {
    display: block;
}

span.AppView-backups--inProgress {
  display: block;
  margin: 0 0 10px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #6b6b6b;
}