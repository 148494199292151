.LinkCheckerView {
    height: 100%;
}
.LinkCheckerView--formContainer {
    height: 50%;
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.LinkCheckerView--output__label {
    font-weight: 500;
    margin-right: 7px;
}

.ui.header.LinkCheckerView--output__header {
    display: flex;
    justify-content: space-between;
}