.ArticleDetail {
	overflow-y: auto;
	max-height: 85vh;
	padding: 0 1rem 0 0.1rem;
}

.dueDateHeader {
	margin: 0 0 1rem;
	font-size: 0.95rem;
	font-weight: 600;
}

.postSubmit {
	margin: 1rem 0;
}

/* .postingDate .input{
    width: 100px !important;
} */

.ArticleDetail .FormHeader .ui.header {
    margin: 0 1rem 0 0 !important;
}

.ArticleDetail--FeedbackForm {
	margin: 2rem 0 2rem;
}

section.articleFeedbackView {
	margin: 1.5rem 0 3rem;
}

.ui.header.articleFeedbackView--header {
	font-size: 1.15rem;
	color: #444;
}

.ArticleDetail--FeedbackForm__header {
    margin: 1rem 0 0.5rem;
    padding: 1rem 0 0;
}

.ArticleDetail--FeedbackForm .DividerHeader {
	margin: 0 0 1rem;
}

.ArticleDetail--feedbackHelperLink {
	display: block;
    margin: 0 0 1rem;
    text-align: center;
}

.ArticleDetail--feedbackHelperLink a {
	font-weight: 600;
	color: #2185d0;
}

section.ArticleDetails {
    margin-top: 1rem;
}

.inputAction .button {
    margin-left: 0.4rem !important;
}

.ArticleDetailItem--container {
	display: flex;
	align-items: center;
}

.ArticleDetailItem--container .ArticleDetailItem:not(:last-child) {
	margin-right: 0.75rem;
}