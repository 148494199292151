.SortedTable tbody tr.selected {
    background: #f7f7f7;
}

.SortedTable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}