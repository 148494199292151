.BasicModalHeader {
    display: flex !important;
    justify-content: space-between;
}

.BasicModalHeader .ModalCloseButton {
    cursor: pointer;
    font-size: 1.7rem;
    font-weight: 300;
    color: #696969;
    transition: 0.2s all;
}

.BasicModalHeader .ModalCloseButton:hover {
    color: #43aeff
}