.SystemNotifications--container {
    background: #0ca3c9;
    color: #fff;
    text-shadow: 0px 1px 1px rgb(0 0 0 / 30%);
    font-size: 1.05rem;
    padding: 0.2rem 1rem;
    text-align: center;
}

.SystemNotifications--render {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SystemNotifications--output {
    display: block;
    width: 100%;
}

.SystemNotifications--closeButton {
    display: block;
    margin: 0 0 0 auto;
    cursor: pointer;
    text-shadow: none;
}