.LinkCheckerOutput {
    overflow-y: auto;
    height: 85vh;
    padding: 1rem 0;
}

.LinkCheckerOutput--totals {
    display: flex;
}

.LinkCheckerOutput--total {
    margin-right: 5rem;
}

span.LinkCheckerOutput--total__output {
    font-weight: 600;
    margin-left: 2px;
    vertical-align: text-top;
}

.LinkCheckerOutput--total {
    margin-right: 3rem;
    border: 1px solid #a7a7a7;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    padding: 1px 10px;
    border-radius: 2px;
    align-items: center;
}