.AssignmentList {
    overflow-y: auto;
    /* max-height: 75vh; */
    height: 85vh;
    padding: 1rem 1rem 0 0;
}

.buttonFilters {
    margin: 0 0 2rem;
}

.AssignmentList .noArticles {
    text-align: center;
}

.Assignments .ActionHeader {
    margin-bottom: 2rem;
}